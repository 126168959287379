
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const current = ref("https://jx.bozrc.com:4433/player/?url=");
    const reesolveAddress = ref("");
    const videoUrl = ref("");
    const loading = ref(false);
    const onAnalysis = () => {
      if (videoUrl.value == '') {
        message.warning("请输入视频链接")
        return
      }
      reesolveAddress.value = current.value + videoUrl.value;
    };
    return {
      current,
      videoUrl,
      loading,
      reesolveAddress,
      onAnalysis
    }
  }
})
