<template>
  <div class="analysis center">
    <iframe
      v-if="reesolveAddress"
      scrolling="no" 
      border="0"
      frameborder="no" 
      framespacing="0" 
      allowfullscreen="true" 
      :src="reesolveAddress">
    </iframe>
    <div class="analysis-cover" v-else>
      <img src="../../../assets/img/analysis-cover.jpg" alt="">
    </div>
    <div class="operation">
      <a-input-group style="display: flex;" compact>
        <a-select v-model:value="current">
          <a-select-option value="https://jx.bozrc.com:4433/player/?url=">接口一</a-select-option>
          <a-select-option value="https://okjx.cc/?url=">接口二</a-select-option>
        </a-select>
        <a-input-search
          v-model:value="videoUrl"
          placeholder="请输入视频链接" 
          style="width: 100%"
          enter-button="开始解析"
          @search="onAnalysis"
        />
      </a-input-group>
    </div>
  </div>
</template>

<script lang="ts">
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const current = ref("https://jx.bozrc.com:4433/player/?url=");
    const reesolveAddress = ref("");
    const videoUrl = ref("");
    const loading = ref(false);
    const onAnalysis = () => {
      if (videoUrl.value == '') {
        message.warning("请输入视频链接")
        return
      }
      reesolveAddress.value = current.value + videoUrl.value;
    };
    return {
      current,
      videoUrl,
      loading,
      reesolveAddress,
      onAnalysis
    }
  }
})
</script>

<style lang="scss" scoped>
  .analysis {
    iframe {
      width: 100%;
      height: 500px;
    }

    .analysis-cover {
      img {
        width: 100%;
        height: 500px;
      }
    }

    .operation {
      margin-top: 30px;
    }
  }
  // 移动端
  @media screen and (max-width: 800px) { 
    .analysis {
      iframe {
        width: 100%;
        height: 200px;
      }
      .analysis-cover {
        img {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
</style>